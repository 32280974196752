import "./styles.css";

function GenericError({ error }) {
  return (
    <div className="generic-error">
      {error.response ? (
        <>
          <h1>{error.response.status}</h1>
          <p>{error.response.data}</p>
        </>
      ) : error.request ? (
        <>
          <h1>No response</h1>
          <p>{error.message}</p>
        </>
      ) : (
        <>
          <h1>Error</h1>
          <p>{error.message}</p>
        </>
      )}
    </div>
  );
}

export default GenericError;
