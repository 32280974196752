import { useLoaderData, useRouteError } from "react-router-dom";
import getResources from "../../api/resource/get";
import "./styles.css";
import { IMAGE_HOST } from "../../api/config";
import GenericError from "../generic-error/index.jsx";
import SectionDespreNoi from "./section-despre-noi.jsx";

const IndexPath = "/";
export default IndexPath;

const resourcesKeys = { cover: "index-about-cover" };

export async function loader() {
  return await getResources(Object.values(resourcesKeys));
}

export function Component() {
  const resources = useLoaderData();
  return (
    <div className="page-index">
      <SectionDespreNoi
        coverResource={{
          id: resourcesKeys.cover,
          type: "image",
          value: IMAGE_HOST + resources[resourcesKeys.cover],
        }}
      />
    </div>
  );
}
Component.displayName = "page-index";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericError error={error} />;
}
ErrorBoundary.displayName = "page-index-error";
