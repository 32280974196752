import ComitetePath from "./page-comitete";
import DiviziiPath from "./page-divizii";
import CDPath from "./page-cd";
import GaleriePath from "./page-galerie";
import AsociatiiPath from "./page-asociatii";
import "./styles.css";

const DespreNoiPath = "/about";
export default DespreNoiPath;

export async function loader({ request }) {
  return {};
}

export function Component() {
  return <></>;
}
Component.displayName = "Component";

export function ErrorBoundary() {
  return <></>;
}
ErrorBoundary.displayName = "ErrorBoundary";

export const children = [
  {
    path: ComitetePath,
    lazy: () => {
      import("./page-comitete");
    },
  },
  {
    path: DiviziiPath,
    lazy: () => {
      import("./page-divizii");
    },
  },
  {
    path: AsociatiiPath,
    lazy: () => {
      import("./page-asociatii");
    },
  },
  {
    path: CDPath,
    lazy: () => {
      import("./page-cd");
    },
  },
  {
    path: GaleriePath,
    lazy: () => {
      import("./page-galerie");
    },
  },
];
