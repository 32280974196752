import "./styles.css";
import Separator from "../../../separator";
import XOTable from "../../../xo-table";

import DespreNoiPath from "../../../../pages/page-despre-noi";
import { id as descriereId } from "../../../../pages/page-despre-noi/section-descriere";
import { id as istoricId } from "../../../../pages/page-despre-noi/section-istoric";
import { id as structuraId } from "../../../../pages/page-despre-noi/section-structura";
import {
  statutLocation,
  roiLocation,
} from "../../../../pages/page-despre-noi/section-statut-roi";
import GaleriePath from "../../../../pages/page-despre-noi/page-galerie";
import AsociatiiPath from "../../../../pages/page-despre-noi/page-asociatii";
import CDPath from "../../../../pages/page-despre-noi/page-cd";
import ComitetePath from "../../../../pages/page-despre-noi/page-comitete";
import DiviziiPath from "../../../../pages/page-despre-noi/page-divizii";
import ScomePath from "../../../../pages/page-despre-noi/page-comitete/page-scome";
import ScophPath from "../../../../pages/page-despre-noi/page-comitete/page-scoph";
import ScorpPath from "../../../../pages/page-despre-noi/page-comitete/page-scorp";
import ScoraPath from "../../../../pages/page-despre-noi/page-comitete/page-scora";
import ExchangesPath from "../../../../pages/page-despre-noi/page-comitete/page-exchanges";
import { id as IGId } from "../../../../pages/page-despre-noi/page-divizii/section-ig";
import { id as CBId } from "../../../../pages/page-despre-noi/page-divizii/section-cb";
import { id as PRId } from "../../../../pages/page-despre-noi/page-divizii/section-pr";
import { id as FRId } from "../../../../pages/page-despre-noi/page-divizii/section-fr";
import { Link } from "react-router-dom";

function SectionDespreNoi({ active }) {
  return (
    <div
      className={
        "navigation-menu-section despre-noi" + (active ? " active" : "")
      }
    >
      <div className="tab-bar">
        <Link to={DespreNoiPath + "#" + descriereId} className="tab-item">
          Descrierea federatiei
        </Link>
        <Separator vertical space="0" color="var(--color-gray-dark)" />
        <Link to={DespreNoiPath + "#" + istoricId} className="tab-item">
          Istoric
        </Link>
        <Separator vertical space="0" color="var(--color-gray-dark)" />
        <a href={statutLocation} className="tab-item">
          Statut
        </a>
        <Separator vertical space="0" color="var(--color-gray-dark)" />
        <a href={roiLocation} className="tab-item">
          Roi
        </a>
        <Separator vertical space="0" color="var(--color-gray-dark)" />
        <Link to={DespreNoiPath + GaleriePath} className="tab-item">
          Galerie
        </Link>
      </div>
      <div className="tables-container">
        <div className="left table-container">
          <Link to={DespreNoiPath + AsociatiiPath} className="title">
            Asociații membre
          </Link>
          <Separator color="var(--color-gray-dark)" />
          <div className="asociatii-table-container">
            <XOTable
              className="asociatii-table"
              space="10%"
              borderColor="var(--color-gray-dark)"
            >
              <tr>
                <td>ASMO</td>
                <td>ASMTMC</td>
                <td>ASSM</td>
              </tr>
              <tr>
                <td>LSTGM</td>
                <td>OSM</td>
                <td>SSMB</td>
              </tr>
              <tr>
                <td>SSMC</td>
                <td>SSMG</td>
                <td>SSMHS</td>
              </tr>
              <tr>
                <td>SSMI</td>
                <td>SSMT</td>
                <td>AUMAM</td>
              </tr>
            </XOTable>
          </div>
        </div>
        <Separator
          vertical
          color="var(--color-gray-dark)"
          space="0"
          style={{ height: "calc(100% - var(--section-gap-medium))" }}
        />
        <div className="right table-container">
          <Link to={DespreNoiPath + "#" + structuraId} className="title">
            Structura federației
          </Link>
          <Separator
            color="var(--color-gray-dark)"
            space="var(--section-gap-big)"
          />
          <div className="right-contents-container">
            <div className="right-columns-container">
              <div className="right-columns">
                <div className="column-container">
                  <Link
                    to={DespreNoiPath + ComitetePath}
                    className="comitet header"
                  >
                    Comitete
                  </Link>
                  <div className="comitete-column">
                    <Link
                      to={DespreNoiPath + ComitetePath + ScomePath}
                      className="comitet"
                    >
                      SCOME
                    </Link>
                    <Link
                      to={DespreNoiPath + ComitetePath + ExchangesPath}
                      className="comitet"
                    >
                      SCOPE-SCORE
                    </Link>
                    <Link
                      to={DespreNoiPath + ComitetePath + ScophPath}
                      className="comitet"
                    >
                      SCOPH
                    </Link>
                    <Link
                      to={DespreNoiPath + ComitetePath + ScoraPath}
                      className="comitet"
                    >
                      SCORA
                    </Link>
                    <Link
                      to={DespreNoiPath + ComitetePath + ScorpPath}
                      className="comitet"
                    >
                      SCORP
                    </Link>
                  </div>
                </div>
                <Separator vertical color="var(--color-gray-dark)" space="0" />
                <div className="column-container">
                  <Link
                    to={DespreNoiPath + DiviziiPath}
                    className="divizie header"
                  >
                    Divizii
                  </Link>
                  <div className="divizii-column">
                    <Link
                      to={DespreNoiPath + DiviziiPath + "#" + FRId}
                      className="divizie"
                    >
                      Fundraising
                    </Link>
                    <Link
                      to={DespreNoiPath + DiviziiPath + "#" + IGId}
                      className="divizie"
                    >
                      It&Grafică
                    </Link>
                    <Link
                      to={DespreNoiPath + DiviziiPath + "#" + PRId}
                      className="divizie"
                    >
                      Public Relations
                    </Link>
                    <Link
                      to={DespreNoiPath + DiviziiPath + "#" + CBId}
                      className="divizie"
                    >
                      Capacity building
                    </Link>
                  </div>
                </div>
              </div>
              <div className="cd-separator-container">
                <Separator color="var(--color-gray-dark)" space="0" />
              </div>
              <Link to={DespreNoiPath + CDPath} className="cd">
                Consiliul director
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionDespreNoi;
