import ScoraPath from "./page-scora";
import ScorpPath from "./page-scorp";
import ScophPath from "./page-scoph";
import ScomePath from "./page-scome";
import ExchangesPath from "./page-exchanges";
import "./styles.css";

const ComitetePath = "/comitees";
export default ComitetePath;

export async function loader({ request }) {
  return {};
}

export function Component() {
  return <></>;
}
Component.displayName = "Component";

export function ErrorBoundary() {
  return <></>;
}
ErrorBoundary.displayName = "ErrorBoundary";

export const children = [
  {
    path: ScoraPath,
    lazy: () => {
      import("./page-scora");
    },
  },
  {
    path: ScorpPath,
    lazy: () => {
      import("./page-scorp");
    },
  },
  {
    path: ScophPath,
    lazy: () => {
      import("./page-scoph");
    },
  },
  {
    path: ScomePath,
    lazy: () => {
      import("./page-scome");
    },
  },
  {
    path: ExchangesPath,
    lazy: () => {
      import("./page-exchanges");
    },
  },
];
