import "./styles.css";

function Resource({ resource, className, ...props }) {
  return (
    <div
      className={
        "resource-container " + resource.type + (className && " " + className)
      }
      {...props}
    >
      {resource.type === "text" ? (
        <>{resource.value}</>
      ) : resource.type === "image" ? (
        <img src={resource.value} alt={resource.id} />
      ) : (
        <></>
      )}
    </div>
  );
}

export default Resource;
