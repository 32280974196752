import { Outlet, ScrollRestoration } from "react-router-dom";
import NavigationBar from "../components/navigation-bar";
import PageFooter from "../components/page-footer";
import ScrollToHash from "../utils/scroll-to-hash";

/**
 * This react component that specifies the general structure and layout of every page.
 * It uses the component 'Outlet' from react-router for the specific content of each page.
 *
 * @returns a react component to be used for the top-level 'Route' component of the router tree.
 */
function PageLayout() {
  return (
    <div style={{ display: "contents" }}>
      <NavigationBar />
      <Outlet />
      <PageFooter />
      <ScrollRestoration />
      <ScrollToHash />
    </div>
  );
}

export default PageLayout;
