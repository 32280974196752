import "./styles.css";

function SectionNoutati({ active }) {
  return (
    <>
      {active && <div className="navigation-menu-section noutati">noutati</div>}
    </>
  );
}

export default SectionNoutati;
