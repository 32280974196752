import axios from "axios";
import { SERVER_HOST } from "../config";

export default async function getResources(resources) {
  const url = SERVER_HOST;

  const result = await axios.get(url, {
    // params: { resources: JSON.stringify(resources) },
  });

  return result;
}
