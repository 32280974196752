import "./styles.css";

const ContactPath = "/contact";
export default ContactPath;

export async function loader({ request }) {
  return {};
}

export function Component() {
  return <></>;
}
Component.displayName = "Component";

export function ErrorBoundary() {
  return <></>;
}
ErrorBoundary.displayName = "ErrorBoundary";
