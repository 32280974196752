import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import PageLayout from "./page-layout";
import IndexPath from "./page-index";
import NoutatiPath from "./page-noutati";
import OportunitatiPath from "./page-oportunitati";
import ResursePath from "./page-resurse";
import DespreNoiPath from "./page-despre-noi";
import SustinePath from "./page-sustine";
import ParteneriSponsoriPath from "./page-parteneri-sponsori";
import ContactPath from "./page-contact";

/**
 * This is the main router of the website and holds information about the page hierarchy.
 *
 * @returns A react component representing the global page router
 */
function Pages() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" Component={PageLayout}>
        <Route path={IndexPath} lazy={() => import("./page-index")} />
        <Route path={NoutatiPath} lazy={() => import("./page-noutati")} />
        <Route
          path={OportunitatiPath}
          lazy={() => import("./page-oportunitati")}
        />
        <Route path={ResursePath} lazy={() => import("./page-resurse")} />
        <Route path={DespreNoiPath} lazy={() => import("./page-despre-noi")} />
        <Route path={SustinePath} lazy={() => import("./page-sustine")} />
        <Route
          path={ParteneriSponsoriPath}
          lazy={() => import("./page-parteneri-sponsori")}
        />
        <Route path={ContactPath} lazy={() => import("./page-contact")} />
        <Route path="*" lazy={() => import("./page-not-found")} />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

export default Pages;
