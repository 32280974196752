import "./styles.css";

const SustinePath = "/support";
export default SustinePath;

export async function loader({ request }) {
  return {};
}

export function Component() {
  return <></>;
}
Component.displayName = "Component";

export function ErrorBoundary() {
  return <></>;
}
ErrorBoundary.displayName = "ErrorBoundary";
