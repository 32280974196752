/**
 * The footer of the website pages. This should be displayed on all pages.
 *
 * @returns The react component.
 */
function PageFooter() {
  return <></>;
}

export default PageFooter;
