import Resource from "../../components/resource";

function SectionDespreNoi({ coverResource }) {
  return (
    <div className="page-index-section despre-noi">
      <Resource resource={coverResource} className="cover" />
      <div className="content">
        <div className="motto">
          Împreună facem în continuare <b>diferența!</b>
        </div>
        <div className="text-container">
          <div className="title">FASMR</div>
          <div className="subtitle">
            Federația Asociațiilor Studenților în Medicină din România
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionDespreNoi;
