import { useEffect, useState } from "react";
import LeftListItem from "./left-list-item";
import "./styles.css";
import NeuronBackground from "../../../images/menu-bacground-neuron-dark-gray.svg";
import SectionDespreNoi from "./section-despre-noi";
import SectionNoutati from "./section-noutati";
import SectionOportunitati from "./section-oportunitati";
import SectionResurse from "./section-resurse";

function NavigationMenu({ active }) {
  const SECTION = { despreNoi: 1, oportunitati: 2, noutati: 3, resurse: 4 };
  const [selectedSection, setSelectedSection] = useState(null);

  useEffect(() => {
    if (!active) setSelectedSection(null);
  }, [active]);

  return (
    <>
      {active && (
        <div className="navigation-menu">
          <div className="overlay-graphics"></div>
          <div className="container">
            <div className="left-list">
              <div className="items-container">
                <LeftListItem
                  href="/"
                  active={selectedSection === SECTION.despreNoi}
                  setActive={(value) => {
                    if (value) setSelectedSection(SECTION.despreNoi);
                  }}
                >
                  Despre noi
                </LeftListItem>
                <LeftListItem
                  href="/"
                  active={selectedSection === SECTION.oportunitati}
                  setActive={(value) => {
                    if (value) setSelectedSection(SECTION.oportunitati);
                  }}
                >
                  Oportunități
                </LeftListItem>
                <LeftListItem
                  href="/"
                  active={selectedSection === SECTION.noutati}
                  setActive={(value) => {
                    if (value) setSelectedSection(SECTION.noutati);
                  }}
                >
                  Noutăți
                </LeftListItem>
                <LeftListItem
                  href="/"
                  active={selectedSection === SECTION.resurse}
                  setActive={(value) => {
                    if (value) setSelectedSection(SECTION.resurse);
                  }}
                >
                  Resurse
                </LeftListItem>
              </div>
              <div className="stretcher" />
            </div>
            <div className="right-side">
              <div className="background-graphics">
                <img src={NeuronBackground} alt="" className="w" />
              </div>
              <SectionDespreNoi
                active={selectedSection === SECTION.despreNoi}
              />
              <SectionOportunitati
                active={selectedSection === SECTION.oportunitati}
              />
              <SectionNoutati active={selectedSection === SECTION.noutati} />
              <SectionResurse active={selectedSection === SECTION.resurse} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default NavigationMenu;
