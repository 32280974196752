import "./styles.css";

const ScoraPath = "/scora";
export default ScoraPath;

export async function loader({ request }) {
  return {};
}

export function Component() {
  return <></>;
}
Component.displayName = "Component";

export function ErrorBoundary() {
  return <></>;
}
ErrorBoundary.displayName = "ErrorBoundary";
